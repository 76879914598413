import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import style from './Styles/index.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { showModal } from '../../store/actions/modalActions'
import { ModalTypes } from '../../types/modals'
import SideBarItem from '../SideBarItem/SideBarItem'
import { routes, RoutesPaths } from '../../router'
import { User } from '../../entity/User'
import { fetchProducts } from '../../store/actions/products/productsActions'
import { fetchQuickAccessProducts } from '../../store/actions/products/quickAccessActions'
import { fetchProductsGroups } from '../../store/actions/products/groupsActions'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import licenseInfo from '../../assets/icons/license_info1.svg'
import { Period } from '../modals/LogsModal/utils'
import { downloadServerLogs } from '../../store/actions/logsActions'
import { fetchNotifications } from '../../store/actions/notificationsActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { getNotificationsFilter } from '../modals/NotificationsModal/utils/getNotificationsFilter'
import { createSignalRConnection } from '../../signalR/createSignalRConnection'
import { subscribeToProductLogs } from '../../signalR/subscribeToProductLogs'
import { startSignalRCommunication } from '../../signalR/startSignalRCommunication'
import { useEffectAfterMount } from '../../hooks/useEffectAfterMount'
import { initiateSignalRMethods } from '../../signalR/initiateSignalRMethods'
import {
    RECONNECTING_SIGNALR,
    showReconnectingMessage,
} from '../../signalR/lib/showReconnectingMessage'

interface ISideBar {
    sidebarHidden: boolean
}

const SideBar: FC<ISideBar> = ({ sidebarHidden }) => {
    const dispatch = useDispatch()
    const authUser = new User()

    const { products } = useTypedSelector((store) => store.products)

    const onLicenseClick = (): void => {
        dispatch(showModal(ModalTypes.LICENCE_MODAL, {}))
    }

    const licence: JSX.Element = (
        <div className="d-flex h-60px w-100 license">
            <div
                className={style.bottomItem}
                onClick={onLicenseClick}
            >
                <img
                    src={licenseInfo}
                    alt="license info"
                />
                {sidebarHidden && <div className="text-light">Toolbox</div>}
            </div>
        </div>
    )

    const handleGetServerLogs = (): void => {
        dispatch(
            showModal(ModalTypes.LOGS_MODAL, {
                dispatcher: (dates: Period) =>
                    dispatch(downloadServerLogs(dates)),
            })
        )
    }

    const serverLogs: JSX.Element = (
        <div className="d-flex h-60px w-100">
            <div
                className={style.bottomItem}
                onClick={handleGetServerLogs}
            >
                <FontAwesomeIcon
                    icon={faDownload}
                    size="1x"
                    className="text-white"
                />
                {sidebarHidden && <div className="text-light">Logs</div>}
            </div>
        </div>
    )

    useEffect(() => {
        dispatch(fetchProducts(true))
        dispatch(fetchQuickAccessProducts())
        dispatch(fetchProductsGroups())
        if (authUser.isFullRightsManagerOrAbove()) {
            const notificationsFilterProductsIds = getNotificationsFilter()
            dispatch(fetchNotifications(notificationsFilterProductsIds))
        }
    }, [dispatch])

    // ======= SignalR ======== //
    useEffectAfterMount(() => {
        const connection = createSignalRConnection()

        connection.onreconnecting(async (_error) => {
            showReconnectingMessage(RECONNECTING_SIGNALR.show)
        })
        connection.onreconnected((_connectionId) => {
            showReconnectingMessage(RECONNECTING_SIGNALR.hide)
        })
        connection.onclose(() => {
            startSignalRCommunication(connection)
                .then(() => subscribeToProductLogs(connection, products))
                .catch(console.error)
        })

        initiateSignalRMethods(connection, dispatch)
        startSignalRCommunication(connection)
            .then(() => subscribeToProductLogs(connection, products))
            .catch(console.error)

        return () => {
            connection.stop().catch(console.error)
        }
    }, [products.length, dispatch]) // no other deps here !important

    return (
        <div
            className={`bg-dark ${style.wrapper} ${
                sidebarHidden ? style.show : style.hidden
            }`}
        >
            <div className="w-100">
                <div className={style.barContainer}>
                    {routes.map((item: any) => {
                        if (
                            authUser.permissions.includes(item.permission) ||
                            (authUser.isViewer() &&
                                (item.path === RoutesPaths.HOME ||
                                    item.path === RoutesPaths.SUMMARY ||
                                    item.path === RoutesPaths.ACCOUNT))
                        ) {
                            return (
                                <SideBarItem
                                    name={item.name}
                                    route={item.path}
                                    icon={item.icon}
                                    dropdown={item.dropdown}
                                    sidebarHidden={sidebarHidden}
                                    key={item.name}
                                />
                            )
                        }
                        return <div key={item.name}></div>
                    })}
                </div>
            </div>
            {authUser.isOwner() && serverLogs}
            {licence}
        </div>
    )
}

export default SideBar
